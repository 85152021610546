import React, {useState} from 'react';
import {createRoot} from 'react-dom/client';

import 'react-perfect-scrollbar/dist/css/styles.css';

import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import {Router, BrowserRouter, Route, Routes} from "react-router-dom";
import {ThemeProvider, createTheme} from '@mui/material/styles';
import Keycloak from "keycloak-js";
import {Provider} from 'react-redux';
import {Boxes} from "./boxes";
import {Casenote} from "./casenote";
import store from './store/store';
import {Button, Container, CssBaseline, IconButton, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import AddIcon from '@mui/icons-material/Add';
import LogoutIcon from '@mui/icons-material/Logout';
import {Manifest} from "./manifest";

const themeMui = createTheme({
    palette: {
        // type: 'dark',
        type: 'light',
        // success: {
        // 	main: '#79f109'
        // }
    },
});

let keycloak = new Keycloak('/keycloak.json');

window._documentData = {};

const useStyles = makeStyles(theme => {
    console.log(theme)
    return {
        mainDiv: {
            paddingTop: '20px'
        },
        haeder: {
            paddingBottom: '20px',
            textAlign: "center",
            height: '80px'
        },
        toolbar: {
            paddingBottom: '20px',
            textAlign: "end"
        },
        spacer: {
            width: '100%'
        },
        hospitalLogo: {
            float: "right",
            lineHeight: '1.1'
        },
        nhsLogo: {
            lineHeight:'1.1',
            backgroundColor:'#1C5DB1',
            color:'white',
            fontStyle:'italic',
            display: 'inline-block',
            fontWeight:'bold'
        }
    }
});

function Component() {
    const [theme, setTheme] = useState(themeMui);
    const classes = useStyles();
    window.setTheme = (theme) => {
        window.localStorage.setItem('fortrus_forms_theme', theme);

        eventBus.fire('globalUpdate');

        switch (theme) {
            case 'dark':
                setTheme(
                    createTheme({
                        palette: {
                            type: 'dark',
                            background: {
                                default: "#232323"
                            },
                            focusedColor: "#a5e5fd",

                        },
                    })
                );
                break;
            case 'light':
                setTheme(
                    createTheme({
                        palette: {
                            type: 'light',
                            background: {
                                default: "#dadada"
                            },
                            focusedColor: "#303f9f",
                        },
                    })
                );
                break;
        }
    };
    console.log("src/maim ok", classes);
    const logout = () => {
        console.log("logout click");
        keycloak.logout();
    }
    return (
        <BrowserRouter>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <React.Fragment>
                        <CssBaseline/>
                        <div className={classes.mainDiv}>
                            <Container fixed className={classes.haeder}>
                                <div style={{width: '100%'}}>
                                    <div style={{display: 'inline-block'}}>
                                        <Typography variant="h6">NMUH - Swisspost Manifest Portal</Typography>
                                    </div>
                                    <div className={classes.hospitalLogo}>
                                        <Typography variant="subtitle2" align='right' className={classes.nhsLogo} style={{lineHeight:'1.1', fontWeight:'bold'}}>NHS</Typography>
                                        <Typography variant="subtitle2" align='right' style={{lineHeight:'1.1'}}>North Middlesex</Typography>
                                        <Typography variant="subtitle2" align='right' style={{lineHeight:'1.1'}}>University Hospital</Typography>
                                        <Typography variant="subtitle2" align='right'  style={{lineHeight:'1.1', color:'#1C5DB1'}}>NHS Trust</Typography>
                                    </div>
                                </div>
                            </Container>
                            <Container fixed className={classes.toolbar}>
                                <Button variant="contained" color="primary"
                                        onClick={() => logout()}><LogoutIcon style={{marginRight:'10px'}}/>Logout</Button>
                            </Container>
                            <Routes>
                                <Route path="/" element={<Manifest auth={keycloak}/>}/>
                                <Route path="manifest/:id"  element={<Boxes auth={keycloak}/>}/>
                                <Route path="box/:id"  element={<Casenote auth={keycloak}/>}/>
                            </Routes>
                        </div>
                    </React.Fragment>{' '}
                </ThemeProvider>
            </Provider>
        </BrowserRouter>
    );
}


keycloak
    .init({
        onLoad: "login-required",
        checkLoginIframe: false,
    })
    .then((init) => {
        if (init) {
            console.log(keycloak);
            const container = document.getElementById('app');
            const root = createRoot(container); // createRoot(container!) if you use TypeScript
            root.render(<Component/>);
        } else {
            // not need to be handled
        }
    });