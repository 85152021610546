import React, {useEffect, useRef, useState} from 'react';


import {makeStyles, withStyles} from '@mui/styles';
import themeStyles from './theme.styles';


import _ from 'lodash';
import {
    Alert, Backdrop,
    Box, Button, CircularProgress,
    Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem,
    Paper, Select, Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField, Typography
} from "@mui/material";


import UndoIcon from '@mui/icons-material/Undo';
import SaveIcon from '@mui/icons-material/Save';
import {useNavigate, useParams} from "react-router-dom";
import {DesktopDatePicker,LocalizationProvider} from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {useDispatch, useSelector} from "react-redux";
import {globalVariable, setGlobalVariable} from "./store/reducers/globals";
const moment = require('moment');
import {API} from "./api/apiService";

function createData(
    id,
    entryNumber,
    type,
    date,
    noteNumber
) {
    return {
        id,
        entryNumber,
        type,
        date,
        noteNumber
    };
}

const useStyles = makeStyles(theme => {
    return {
        toolbar: {
            paddingBottom: '20px',
            textAlign: "start"
        },
        backButton: {
            marginLeft: '10px !important'
        },
        cninput: {
            minWidth: '220px !important',
            paddingRight: '10px !important'
        }
    }
});

const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

    return [ htmlElRef, setFocus ]
}

function Casenote(props) {
    const { id } = useParams();
    const variables = useSelector(globalVariable);
    const [inputRef, setInputFocus] = useFocus();
    const [rows, setRows] = React.useState([]);
    const [alert, setAlert] = React.useState(false);
    const [alertText, setAlertText] = React.useState('');
    const [typec, setTypec] = React.useState('');
    const [numberc, setNumberc] = React.useState('');
    const [datec, setDatec] = React.useState(null);
    const [box, setBox] = React.useState(null);
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { manifest } = variables;
    const [progress,setProgress] = useState(false);
    const [errorText, setErroText] = React.useState("");
    const [snack, setSnack] = React.useState(false);

    useEffect(() => {
        console.log('useEffect',numberc);
        if(!manifest) {
            dispatch(setGlobalVariable({name: 'manifest', value: null}));
            dispatch(setGlobalVariable({name: 'error', value: 'notfound'}));
            navigate('/');
        } else {
            if(!box) {
                let _box = _.find(manifest.boxes, item => {
                    return item.id === id;
                });
                if (!_box) {
                    navigate(`/manifest/${manifest._id}`);
                } else {
                    setBox(_box);
                    setRows(_box.casenotes);
                }
            }
        }
        setInputFocus();
    });

    const handleCloseAlert = () => {
        setAlert(false);
    }
    const gotToBoxes = () => {
        setProgress(true);
        let boxC = _.cloneDeep(box);
        boxC.casenotes = rows;
        let manifestC = _.cloneDeep(manifest);
        var index = _.findIndex(manifestC.boxes, {id: box.id});
        manifestC.boxes.splice(index, 1, boxC);
        API.updateManifest(manifestC, props.auth.token).then((resManifest) => {
            setProgress(false);
            if(!resManifest) {
                setErroText("Error occured in saving manifest!");
                setSnack(true);
            }else {
                dispatch(setGlobalVariable({name: 'manifest', value: resManifest}));
                navigate(`/manifest/${resManifest._id}`);
            }
        })


        //navigate('/manifest/33333');
    }
    const handleChangeDate = (value) => {
        setDatec(value);
    };
    const handleChangeType = (event) => {
        setTypec(event.target.value);
    };

    const handleChangeNumber = (event) => {
        setNumberc(event.target.value);
        console.log('handleChangeNumber',numberc);
    };

    const updateRows = (urows) => {
        for(let i = 0 ; i<urows.length; i++) {
            urows[i].id = i;
            urows[i].entryNumber = `${i+1}`;
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if(!numberc || numberc.length<2) {
                setAlertText('Casenote number needs to be at least two letter long');
                setAlert(true);
                return;
            }
            if(typec !== 'DayForward' && typec!== 'Historical') {
                setAlertText('Please provide casenote type');
                setAlert(true);
                return;
            }
            if(!datec || !moment.isMoment(datec)) {
                setAlertText('Please provide correct SPS collection date');
                setAlert(true);
                return;
            }
            let newrows = _.cloneDeep(rows);
            newrows.push(createData('1','1',typec,datec.format('DD/MM/YYYY'),numberc));
            updateRows(newrows);
            setRows(newrows);
            setNumberc('');
            setInputFocus();
        }
    }
    const deletRow = (row) => {
        let newrows = _.cloneDeep(rows);
        _.remove(newrows, function(crow) {return crow.id === row.id});
        updateRows(newrows);
        setRows(newrows);
        setNumberc('');
        setInputFocus();
    }
    const handleCloseSnack = () => {
        setSnack(false);
    }
    return (
        <React.Fragment>
            <Container fixed className={classes.toolbar}>
                <Button variant="contained"
                        color="primary"
                        onClick={() => gotToBoxes()}>
                    <SaveIcon style={{marginRight: '10px'}}/>
                    <UndoIcon style={{marginRight: '10px'}}/>
                    Save and back to boxes
                </Button>
            </Container>
            <Container fixed className={classes.toolbar}>
                <FormControl className={classes.cninput}>
                    <InputLabel id="demo-simple-select-label">Casenote Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={typec}
                        label="Casenote Type"
                        onChange={handleChangeType}
                    >
                        <MenuItem value={'DayForward'}>DayForward</MenuItem>
                        <MenuItem value={'Historical'}>Historical</MenuItem>
                    </Select>
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterMoment} >
                    <DesktopDatePicker
                        className={classes.cninput}
                        label="SPS Collection Date"
                        inputFormat="MM/DD/YYYY"
                        value={datec}
                        onChange={handleChangeDate}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
                <TextField
                    ref={inputRef}
                    className={classes.cninput}
                    id="outlined-casenote-number"
                    label="Casenote Number"
                    value={numberc}
                    onChange={handleChangeNumber}
                    onKeyDown={handleKeyDown}
                />
            </Container>
            <Container fixed>
                <Box sx={{width: '100%', height: '100vh'}}>
                    <div style={{paddingBottom: '5px'}}><Typography>BOX Number: {(box ? box.id : '')}</Typography></div>
                    <TableContainer component={Paper}>
                        <Table sx={{minWidth: 650}} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{'Entry Number'}</TableCell>
                                    <TableCell>{'Casenote Type'}</TableCell>
                                    <TableCell>{'SPS Collection Date'}</TableCell>
                                    <TableCell>{'Note Number'}</TableCell>
                                    <TableCell>{''}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell>{row.entryNumber}</TableCell>
                                        <TableCell>{row.type}</TableCell>
                                        <TableCell>{row.date}</TableCell>
                                        <TableCell>{row.noteNumber}</TableCell>
                                        <TableCell><Button color='error'
                                                           onClick={()=>deletRow(row)}
                                                           variant="contained">Delete
                                                    </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Container>
            <Snackbar open={snack} autoHideDuration={6000} onClose={handleCloseSnack}
                      anchorOrigin={{vertical:'top',horizontal:'center'}}>
                <Alert onClose={handleCloseSnack} severity="error" sx={{ width: '100%' }}>
                    {errorText}
                </Alert>
            </Snackbar>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={progress}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog open={alert}
                    onClose={handleCloseAlert}
                    maxWidth='sm'
                    fullWidth={true}
            >
                <DialogTitle>Missing</DialogTitle>
                <DialogContent>
                    <Alert severity="error">{alertText}</Alert>
                </DialogContent>
                <DialogActions>
                    <Button color='error' onClick={handleCloseAlert}>Ok</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}


Casenote = withStyles(themeStyles)(Casenote);

export {Casenote};