import React, {useEffect, useRef, useState} from 'react';


import {makeStyles, withStyles} from '@mui/styles';
import themeStyles from './theme.styles';
import {useNavigate, useParams} from 'react-router-dom';

import _ from 'lodash';
import {
    Alert,
    Backdrop,
    Box, Button, CircularProgress,
    Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Paper, Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField, Typography
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import UndoIcon from "@mui/icons-material/Undo";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import { useSelector, useDispatch } from 'react-redux';
import {setGlobalVariable,globalVariable} from './store/reducers/globals';
import {API} from "./api/apiService";
import { CSVLink } from "react-csv";

const moment = require('moment');

function createData(
    number,
    created,
    count
) {
    return {
        number,
        created,
        count
    };
}

const useStyles = makeStyles(theme => {
    return {
        toolbar: {
            paddingBottom: '20px',
            textAlign: "start"
        },
        backButton: {
            marginLeft: '10px !important'
        }
    }
});


function Boxes(props) {

    const headers = [
        { label: "Hospital Number", key: "hospitalnumber" },
        { label: "Box Number", key: "boxnumber" },
        { label: "Collection Date", key: "collectiodate" },
        { label: "Casenote Type", key: "type" }
    ];
    const csvLink = useRef(null);
    const { id } = useParams();
    const variables = useSelector(globalVariable);
    const [exportWarning,setExportWarning]  = React.useState(false);
    const [newNumber, setNewNumber] = React.useState("");
    const [data, setDAta] = React.useState([]);
    const [errorText, setErroText] = React.useState("");
    const [description, setDescription] = React.useState("Loading...");
    const [snack, setSnack] = React.useState(false);
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    const navigate = useNavigate();
    const [progress,setProgress] = useState(false);
    const { manifest } = variables;
    const loadManifest = () => {
        setProgress(true);
        API.getManifest(id,props.auth.token).then((manifestDB) => {
            if(!manifestDB) {
                dispatch(setGlobalVariable({name: 'manifest', value: null}));
                dispatch(setGlobalVariable({name: 'error', value: 'notfound'}));
                setProgress(false);
                navigate('/');
            }else {
                if(!manifestDB.boxes || !_.isArray(manifestDB.boxes)) {
                    manifestDB.boxes = [];
                }
                dispatch(setGlobalVariable({name: 'manifest', value: manifestDB}));
                setProgress(false);
            }
        })
    }



    const handleCloseSnack = () => {
        setSnack(false);
    }

    const createBox = () => {
        setOpen(false);
        if(!newNumber || newNumber.length<0) {
            setErroText('Incorrect box number.');
            setSnack(true);
        }else {
            let box = _.find(manifest.boxes, item => {
                return item.id === newNumber;
            });
            if(box) {
                setErroText('Box with provided number exist!');
                setSnack(true);
            } else {
                box = {
                    id: newNumber,
                    user : props.auth.tokenParsed.name,
                    casenotes: [] ,
                    createDate : moment().format('DD/MM/YYYY HH:mm') }
                let manifestC = _.cloneDeep(manifest);
                manifestC.boxes.push(box);
                dispatch(setGlobalVariable({name: 'manifest', value: manifestC}));
                gotToBox(newNumber);
            }
        }
    }
    useEffect(() => {
        if(manifest) {
            setDescription(`Manifest cerated ${moment(manifest.createDate).format('DD/MM/YYYY HH:mm')} by ${manifest.user} ${(manifest.exported ? ' EXPORTED' : '')}`);
            let csvData = [];
            _.each(manifest.boxes, (box)=> {
                _.each(box.casenotes, (cs)=> {
                    csvData.push(
                        {
                            hospitalnumber : cs.noteNumber,
                            boxnumber: box.id,
                            collectiodate: cs.date,
                            type : cs.type
                        }
                    )
                });
            });
            setDAta(csvData);
        }
    },[manifest]);

    useEffect(() => {
        if(!variables.manifest || variables.manifest._id !== id) {
            loadManifest();
        }
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCloseEWarn = () => {
        setExportWarning(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const gotToBox = (number) => {
        navigate(`/box/${number}`);
    }

    const gotToManifests = () => {
        setOpen(false);
        navigate('/');
    }

    const exportManifest = () => {
        setExportWarning(true);
    }
    const doExportManifest = () => {
        setExportWarning(false);
        setExportWarning(false);
        let manifestC = _.cloneDeep(manifest);
        manifestC.exported = true;
        API.updateManifest(manifest,props.auth.token).then((manifestDB) => {
            dispatch(setGlobalVariable({name: 'manifest', value: manifestC}));
            csvLink.current.link.click();
        });
    }

    const deletRow = (row) => {
        let manifestC = _.cloneDeep(manifest);
        _.remove(manifestC.boxes, item => {
            return item.id === row.id;
        });
        dispatch(setGlobalVariable({name: 'manifest', value: manifestC}));
    }

    return (
        <React.Fragment>
            <Container fixed className={classes.toolbar}>
                <Button variant="contained"
                        color="success"
                        onClick={() => handleClickOpen()}>
                    <AddIcon style={{marginRight:'10px'}}/>
                    New Box
                </Button>
                    <Button variant="contained"
                            className={classes.backButton}
                            color="primary"
                            onClick={() => exportManifest()}
                            >
                        <SystemUpdateAltIcon style={{marginRight:'10px'}}/>
                        Export manifest
                    </Button>
                <Button variant="contained"
                        className={classes.backButton}
                        color="primary"
                        onClick={() => gotToManifests()}>
                    <UndoIcon style={{marginRight:'10px'}}/>
                    Back to manifest list
                </Button>
            </Container>
            <CSVLink headers={headers}
                     data={data}
                     filename={`manifest.csv`}
                     asyncOnClick={true}
                     style={{color: 'transparent'}}
                     target="_blank"
                     ref={csvLink}
                     >
            </CSVLink>
            <Container fixed>
                <Box sx={{width: '100%', height: '100vh'}}>
                    <div style={{paddingBottom: '5px'}}><Typography>{description}</Typography></div>
                    {manifest &&  (<TableContainer component={Paper}>
                        <Table sx={{minWidth: 650}} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{'Box Number'}</TableCell>
                                    <TableCell>{'Date created'}</TableCell>
                                    <TableCell>{'Casenotes'}</TableCell>
                                    <TableCell>{''}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {manifest.boxes.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell>{row.createDate}</TableCell>
                                        <TableCell>{row.casenotes.length}</TableCell>
                                        <TableCell>
                                            <Button color='success'
                                                    onClick={() => gotToBox(row.id)}
                                                    variant="contained">{manifest.exported ? 'View' : 'Edit'}
                                            </Button>
                                            {
                                                row.casenotes.length<1 && !manifest.exported &&(
                                                    <Button color='error'
                                                            style={{marginLeft:'5px'}}
                                                            onClick={()=>deletRow(row)}
                                                            variant="contained">Delete
                                                    </Button>
                                                )
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>)}
                </Box>
            </Container>
            <Snackbar open={snack} autoHideDuration={6000} onClose={handleCloseSnack}
                      anchorOrigin={{vertical:'top',horizontal:'center'}}>
                <Alert onClose={handleCloseSnack} severity="error" sx={{ width: '100%' }}>
                    {errorText}
                </Alert>
            </Snackbar>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={progress}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog open={open}
                    onClose={handleClose}
                    maxWidth='sm'
                    fullWidth={true}
            >
                <DialogTitle>SPS Box Number</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Box Number"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={newNumber}
                        onChange={(event) => {setNewNumber(event.target.value)}}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color='error' onClick={handleClose}>Cancel</Button>
                    <Button onClick={() => createBox()}>OK</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={exportWarning}
                    onClose={handleCloseEWarn}
                    maxWidth='sm'
                    fullWidth={true}
            >
                <DialogTitle>New Manifest</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are yopu sure you want to export Manifest? You cannot modify it after that.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color='error' onClick={handleCloseEWarn}>Cancel</Button>
                    <Button onClick={() => doExportManifest()}>OK</Button>
                </DialogActions>
            </Dialog>

        </React.Fragment>
    )
}


Boxes = withStyles(themeStyles)(Boxes);

export {Boxes};