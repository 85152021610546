import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';

import cfg from './../config';

class API {
    constructor(props) {
    }

    server() {
        return cfg.host;
    }

    version() {
        return 1;
    }

        getList(pagesize,page,onlymy,lastupdated,token) {
        let cfg = {
            headers: {
                Authorization: "Bearer " + token,
            },
            params: {},
        };
        const that = this;
        let optional = '';
        if(onlymy) {
            optional = `${optional}&onlymy=true`;
        }
        if(lastupdated) {
            optional = `${optional}&lastupdated=true`;
        }
        return new Promise((resolve) => {
            axios
                .get(that.server() + `list/?page=${page}&pagesize=${pagesize}${optional}`,cfg)
                .then(function (response) {
                    if (response.status === 200) {
                        resolve(response.data);
                    } else {
                        console.log('getList: (' + response.status + ') ' + response.statusText);
                        resolve(null)
                        // TODO: show tooltip ?
                    }
                })
                .catch(function (error) {
                    console.error(error);
                    // FIXME: !
                    resolve(null);
                });
        });
    }

    getManifest(id,token) {
        let cfg = {
            headers: {
                Authorization: "Bearer " + token,
            },
            params: {},
        };
        const that = this;
        return new Promise((resolve) => {
            axios
                .get(that.server() + `get/${id}`,cfg)
                .then(function (response) {
                    if (response.status === 200) {
                        resolve(response.data);
                    } else {
                        console.log('getList: (' + response.status + ') ' + response.statusText);
                        resolve(null)
                        // TODO: show tooltip ?
                    }
                })
                .catch(function (error) {
                    console.error(error);
                    // FIXME: !
                    resolve(null);
                });
        });
    }

    updateManifest(manifest,token) {
        let cfg = {
            headers: {
                Authorization: "Bearer " + token,
            },
            params: {},
        };
        const that = this;
        return new Promise((resolve) => {
            axios
                .post(that.server(),manifest,cfg)
                .then(function (response) {
                    if (response.status === 200) {
                        resolve(response.data);
                    } else {
                        console.log('getList: (' + response.status + ') ' + response.statusText);
                        resolve(null)
                        // TODO: show tooltip ?
                    }
                })
                .catch(function (error) {
                    console.error(error);
                    // FIXME: !
                    resolve(null);
                });
        });
    }

}

const api = new API();
export { api as API };