const _ = require('lodash');

const defConf = {
    host: 'http://localhost:3000/',
}


let customConf = undefined;
if (typeof webConfig === "function") {
    customConf = webConfig();
}

const conf = _.merge(defConf, customConf);
if (process.env["NODE_ENV"] === "development") {
    console.log("Merged configuration: " + JSON.stringify(conf));
}

export default conf;
