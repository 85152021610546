import {createSlice} from "@reduxjs/toolkit";

export const globalsSlice = createSlice({
    name: "globals",
    initialState: {
        variables: {
            scrollCount: 0,
        },
    },
    reducers: {
        setGlobalVariable: (state, variable) => {
            state.variables[variable.payload.name] = variable.payload.value;
        },
        updateScroll: (state) => {
            state.variables.scrollCount++;
        },
    },
});

export const {setGlobalVariable, updateScroll} = globalsSlice.actions;

export const globalVariable = state => state.globals.variables;
export const onUpdateScroll = state => state.globals.variables.scrollCount;

export default globalsSlice.reducer;
