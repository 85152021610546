export default theme => {
    let buttons = {
        // margin: theme.spacing(1),
        // padding: theme.spacing(1)
    }
    return {
        tabContent: {
            // height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - 48px)`
            height: `calc(100vh - 36px - 48px - 2*${buttons.margin}px - 2*${buttons.padding}px - 10px)`,
        },
        buttons: buttons,
        grid: {
            width: "100%",
            height: "100%",
            position: "absolute",
            overflow: "hidden",
        },
        item: {
            width: "2rem",
            height: "2rem",
        },
        error: {
            width: "50%",
            height: "50%",
        },
        // input label when focused
        '@global': {
            "label.MuiFormLabel-root":{
                "&.Mui-focused": {
                    color: theme.palette.focusedColor
                }
            },
            // focused color for input with variant='standard'
            ".MuiInput-underline:after": {
                borderBottomColor: theme.palette.focusedColor
            },
            // focused color for input with variant='filled'
            ".MuiFilledInput-underline:after": {
                borderBottomColor: theme.palette.focusedColor
            },
            // focused color for input with variant='outlined'
            ".MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                    borderColor: theme.palette.focusedColor,
                    "&.MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.palette.focusedColor
                    }
                }
            }
        }
    };
}