import {configureStore} from "@reduxjs/toolkit";

import globalsReducer from "./reducers/globals";

export default configureStore({
    reducer: {
        globals: globalsReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false,
    }),
});
