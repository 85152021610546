import React, {useState, useEffect} from 'react';


import {makeStyles, withStyles} from '@mui/styles';
import themeStyles from './theme.styles';
import {useNavigate} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {setGlobalVariable,globalVariable} from './store/reducers/globals';
import _ from 'lodash';
import {API} from './api/apiService'

import {
    Alert,
    Backdrop,
    Box, Button, CircularProgress,
    Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Paper, Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow, TextField
} from "@mui/material";

import CheckIcon from '@mui/icons-material/Check'
import AddIcon from "@mui/icons-material/Add";
import LogoutIcon from "@mui/icons-material/Logout";


const moment = require('moment');

function createData(
    id,
    date,
    count,
    user,
    exported
) {
    return {
        id,
        date,
        count,
        user,
        exported
    };
}

const useStyles = makeStyles(theme => {
    return {
        toolbar: {
            paddingBottom: '20px',
            textAlign: "start"
        },
    }
});

// const rows = [
//     createData('30181111', '04/08/2022 14:00', 10, 'gordon',true),
//     createData('30181345', '03/08/2022 12:21', 5, 'gordon',true),
//     createData('30181189', '02/08/2022 13:00', 2, 'thowp',false),
//     createData('30181173', '01/08/2022 16:07', 1, 'gordon',false)
// ]

function Manifest(props) {
    const variables = useSelector(globalVariable)
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false);
    const [snackError, setSnackError] = useState(false);
    const [progress,setProgress] = useState(false);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [rows, setRows] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const classes = useStyles();
    const navigate = useNavigate();
    useEffect(() => {
        setProgress(true);
        API.getList(rowsPerPage, page, false, false, props.auth.token).then(async (results) => {
            setProgress(false);
            setRows(results.boxes);
            setTotal(results.count)
            if(variables.error === 'notfound') {
                dispatch(setGlobalVariable({name: 'error', value: null}));

            }
        })
    }, [rowsPerPage, page]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCloseSnack = () => {
        setSnackError(false);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const gotToManifest = (manifest) => {
        setOpen(false);
        if(!manifest) {
            setProgress(true);
            API.updateManifest({},props.auth.token).then(async (results) => {
                setProgress(false);
                navigate(`/manifest/${results._id}`);
            })
        } else {
//            dispatch(setGlobalVariable({name: 'manifest', value: 'test'}))
            setOpen(false);
            navigate(`/manifest/${manifest._id}`);
        }
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <React.Fragment>
            <Container fixed className={classes.toolbar}>
                <Button variant="contained" color="success" onClick={() => handleClickOpen()}
                ><AddIcon style={{marginRight: '10px'}}/>New Manifest</Button>
            </Container>
            <Container fixed>
                <Box sx={{width: '100%', height: '100vh'}}>

                    <TableContainer component={Paper}>
                        <Table sx={{minWidth: 650}} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{'Date created'}</TableCell>
                                    <TableCell>{'User'}</TableCell>
                                    <TableCell>{'Boxes'}</TableCell>
                                    <TableCell>{'Exported'}</TableCell>
                                    <TableCell>{''}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows && (rows.map((row) => (
                                    <TableRow
                                        key={row._id}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell>{moment(row.createDate).format('DD/MM/YYYY :HH:mm')}</TableCell>
                                        <TableCell>{row.user}</TableCell>
                                        <TableCell>{row.boxes ? row.boxes.length : 0}</TableCell>
                                        <TableCell>{row.exported ?
                                            (<div style={{width: "10px", marginTop: 10}}><CheckIcon/></div>) :
                                            (<div style={{width: "10px", marginTop: 10}}></div>)}
                                        </TableCell>
                                        <TableCell><Button color='success' onClick={() => gotToManifest(row)}
                                                           variant="contained">{(row.exported ? 'View' : 'Edit')}</Button></TableCell>
                                    </TableRow>
                                )))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </Container>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={progress}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar open={snackError} autoHideDuration={6000} onClose={handleCloseSnack}
                      anchorOrigin={{vertical:'top',horizontal:'center'}}>
                <Alert onClose={handleCloseSnack} severity="error" sx={{ width: '100%' }}>
                    Manifest with given ID not found!
                </Alert>
            </Snackbar>
            <Dialog open={open}
                    onClose={handleClose}
                    maxWidth='sm'
                    fullWidth={true}
            >
                <DialogTitle>New Manifest</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are yopu sure you want to create new Manifest?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color='error' onClick={handleClose}>Cancel</Button>
                    <Button onClick={() => gotToManifest(null)}>OK</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}


Manifest = withStyles(themeStyles)(Manifest);

export {Manifest};